<template>
  <a-layout class="custom-layout">
    <a-layout-content class="custom-content auto">
      <a-spin :spinning="loading">
        <div class="content-title auto">
          <div class="coustom-title fl">已接入系统（{{count}}个）</div>
        </div>
        <div class="coustom-title-mes auto">
          <a-icon type="info-circle" theme="filled" style="color:#226FBB;margin-right: 10px;" />接入系统共 {{count}} 个，其中：CAS协议 {{serviceCount.web || 0}} 个，RESTful接口 {{serviceCount.rest || 0}} 个，OAuth协议 {{serviceCount.oauth || 0}} 个，LDAP协议 {{serviceCount.ldap || 0}} 个
        </div>
        <div class="content-bar auto">
          <custom-page :total="count" @getPageMes="getPageMes" :page="filter.page" :limit="filter.limit">
            <div slot="custom">
              <a-button type="primary" size="small" class="m-r-5 fl" @click="addAccess"> 添加系统 </a-button>
              <a-input-search size="small" placeholder="请输入内容" v-model="filter.search" style="width: 140px" class="fl" />
            </div>
          </custom-page>
        </div>
        <div class="content-table auto">
          <a-table :rowKey="(record,index)=>{return index}" :columns="tablecolumns" :data-source="tableData" size="middle" :pagination="false">
            <div slot="serviceType" slot-scope="text">
              {{serviceTypeOptions.find(item => {return item.id === text})['name']}}
            </div>
            <div slot="enabled" slot-scope="text">
              <span v-if="text">
                <a-badge status="success" text="启用" />
              </span>
              <span v-else>
                <a-badge status="error" text="停用" />
              </span>
            </div>
            <div slot="expirationDate" slot-scope="text">
              <span v-if="text">
                {{text}}
              </span>
              <span v-else>
                未设置
              </span>
            </div>
            <div slot="action" slot-scope="text, record">
              <a-button type="primary" size="small" class="m-r-5" @click="editHandle(record)">编辑</a-button>
              <a-popconfirm
                title="确定要删除吗?"
                ok-text="确定"
                cancel-text="取消"
                placement="topRight"
                @confirm="disabledOff(record)"
              >
                <a-button type="primary" size="small" class="bgred borred">删除</a-button>
              </a-popconfirm>
            </div>
          </a-table>
        </div>
        <div class="content-bar auto">
          <custom-page :total="count" @getPageMes="getPageMes" :page="filter.page" :limit="filter.limit">
            <div slot="custom">
              <a-button type="primary" size="small" class="m-r-5 fl" @click="addAccess"> 添加系统 </a-button>
              <a-input-search size="small" placeholder="请输入内容" v-model="filter.search" style="width: 140px" class="fl" />
            </div>
          </custom-page>
        </div>
      </a-spin>
    </a-layout-content>
    <a-drawer
      width="100%"
      title=""
      class="access-drawer"
      placement="right"
      :closable="false"
      :visible="visible"
      :get-container="false"
      :destroyOnClose="true"
      :wrap-style="{ position: 'absolute' }"
    >
      <a-layout class="custom-layout">
        <a-layout-sider class="custom-sider" width="180">
          <a-button class="back-btn" size="small" @click="onClose"><a-icon type="double-left" class="font12" />返回</a-button>
          <div class="batch-menu">
            <ul>
              <li :class="menuIndex === 1 ? 'cur' : ''" @click="changeMenu(1)"><span>基本设置</span></li>
              <li :class="menuIndex === 2 ? 'cur' : ''" @click="changeMenu(2)" v-show="addForm.serviceType !== 'ldap'"><span>联系人</span></li>
              <li :class="menuIndex === 3 ? 'cur' : ''" @click="changeMenu(3)" v-show="addForm.serviceType !== 'ldap'"><span>准入授权</span></li>
            </ul>
          </div>
        </a-layout-sider>
        <a-layout-content class="custom-content auto" style="position:relative;" ref="content" @scroll="scrollForm">
          <a-spin :spinning="editLoading">
            <div class="drawer-bar auto">
              <a-button type="primary" size="small" class="m-r-10" @click="saveAccess">保存</a-button>
              <span class="font16"><b>系统接入详情</b></span>
            </div>
            <a-form-model :model="addForm" :rules="addRules" ref="addForm" id="access-form" :label-col="{span: 3}" :wrapper-col="{span: 18}">
              <div class="drawer-item auto" ref="basic">
                <div class="drawer-title font16 grey999"><b>基本设置</b></div>
                <div class="drawer-content auto" id="no-margin-form" style="width:70%;">
                  <a-form-model-item prop="serviceType" label="接入方式" ref="serviceType">
                    <a-select v-model="addForm.serviceType" @change="changeServiceType" placeholder="接入方式" allowClear size="small" style="width: 156px"> <!-- :disabled="!isAdd" -->
                      <a-select-option :value="item.id" v-for="(item, index) in serviceTypeOptions" :key="index" :disabled="item.disabled">{{item.name}}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item prop="name" label="系统名称" ref="name" class="checkrepeatitem">
                    <a-input type="text" size="small" v-model.trim="addForm.name" style="width: 100%" @change="checkNameRepeat" placeholder="请输入系统名称" />
                    <span class="repeattips" v-if="repeatNameStatus">系统名称重复</span>
                  </a-form-model-item>
                  <a-form-model-item prop="username" label="管理账号" ref="username" v-show="addForm.serviceType === 'ldap'">
                    <a-input type="text" size="small" @blur="blurUsername" v-model.trim="addForm.username" style="width: 100%" placeholder="请输入用户名" />
                  </a-form-model-item>
                  <a-form-model-item prop="manageDn" label="管理DN" ref="manageDn" v-show="addForm.serviceType === 'ldap'">
                    <a-input type="text" size="small" v-model.trim="addForm.manageDn" disabled style="width: 100%" placeholder="输入管理账号后自动生成" />
                  </a-form-model-item>
                  <a-form-model-item prop="password" label="管理密码" ref="password" v-show="addForm.serviceType === 'ldap'">
                    <a-input type="text" size="small" v-if="showPass || isAdd" v-model.trim="addForm.password" style="width: calc(100% - 77px);margin-right: 5px;" placeholder="请输入管理密码" />
                    <a-input type="password" size="small" :disabled="true" v-else v-model.trim="fakepass" style="width: calc(100% - 77px);margin-right: 5px;" placeholder="请输入管理密码" @click="showPassFn" />
                    <a-button size="small" @click="getGeneratePwd">重新生成</a-button>
                  </a-form-model-item>
                  <a-form-model-item prop="baseDn" label="BaseDN" ref="baseDn" v-show="addForm.serviceType === 'ldap'">
                    <a-input type="text" size="small" v-model.trim="addForm.baseDn" disabled style="width: 100%" placeholder="" />
                  </a-form-model-item>
                  <a-form-model-item prop="serviceId" label="URL规则" ref="serviceId" v-show="addForm.serviceType !== 'ldap'" class="checkrepeatitem">
                    <a-input type="text" size="small" v-model.trim="addForm.serviceId" style="width: 100%" @change="checkServiceIdRepeat" placeholder="请输入URL规则" />
                    <span class="repeattips" v-if="repeatServiceIdStatus">规则重复</span>
                  </a-form-model-item>
                  <a-form-model-item prop="order" label="匹配顺序" ref="order" v-show="addForm.serviceType !== 'ldap'">
                    <a-input type="number" size="small" v-model.trim="addForm.order" style="width: 90px" placeholder="请输入URL规则" />
                  </a-form-model-item>
                  <a-form-model-item prop="appId" label="APPID" v-if="addForm.serviceType === 'oauth'" ref="appId">
                    <a-input type="text" size="small" v-model.trim="addForm.appId" style="width: 100%" disabled />
                  </a-form-model-item>
                  <a-form-model-item prop="appSecert" label="AppSecret" v-if="addForm.serviceType === 'oauth'" ref="appSecert">
                    <a-input type="text" size="small" v-model.trim="addForm.appSecert" disabled style="width: calc(100% - 77px);margin-right: 5px;" />
                    <a-button size="small" @click="getAppAecret('appsecret')">重新生成</a-button>
                  </a-form-model-item>
                  <a-form-model-item prop="restfuls" label="IP列表" v-if="addForm.serviceType === 'rest'" ref="restfuls">
                    <div class="ip-box auto fl fl m-r-5" ref="ipBox">
                      <div v-for="(item, index) in addForm.restfuls" :key="index" class="restful-item">
                        <span class="fl">{{item}}</span>
                        <!-- <a href="javascript:;" class="alink fr font12" @click="deleteRestfuls(index)">删除</a> -->
                        <a-icon type="delete" class="del-icon fr" @click="deleteRestfuls(index)" />
                      </div>
                      <div v-show="isAddRestful">
                        <a-input v-model="newRestful" size="small" class="fl" style="width:240px;"></a-input>
                        <a href="javascript:;" class="alink fr font12" @click="submitRestfuls" style="line-height:22px;">确定</a>
                      </div>
                    </div>
                    <a-button size="small" @click="addRestfuls" v-show="addForm.serviceType === 'rest'">添加IP</a-button>
                    <!-- v-show="isAdd" -->
                  </a-form-model-item>
                  <a-form-model-item prop="enabled" label="是否启用" ref="enabled">
                    <a-switch v-model.trim="addForm.enabled" size="small" @change="changeEnabled" />
                  </a-form-model-item>
                  <a-form-model-item prop="enabledSso" label="参与SSO" ref="enabledSso" v-show="addForm.serviceType !== 'ldap'">
                    <a-switch v-model.trim="addForm.enabledSso" size="small" />
                  </a-form-model-item>
                  <a-form-model-item prop="allowedAttributes" label="返回属性" ref="allowedAttributes" v-show="addForm.serviceType !== 'ldap'">
                    <a-select mode="multiple" v-model="addForm.allowedAttributes" placeholder="返回属性" allowClear size="small" style="width: 100%">
                      <a-select-option :value="item.id" v-for="(item, index) in filters['attr']" :key="index">{{item.name}}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item prop="allowedAttributes" label="授权字段" ref="allowedAttributes" v-show="addForm.serviceType === 'ldap'">
                    <a-select mode="multiple" v-model="addForm.allowedAttributes" placeholder="授权字段" allowClear size="small" style="width: 100%">
                      <a-select-option :value="item.id" v-for="(item, index) in filters['attrldap']" :key="index">{{item.name}}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item prop="allowedTags" label="人员范围" ref="allowedTags" v-show="addForm.serviceType === 'ldap'">
                    <a-select mode="multiple" v-model="addForm.allowedTags" placeholder="授权字段" allowClear size="small" style="width: 100%">
                      <a-select-option :value="item.key" v-for="(item, index) in filters['tag']" :key="index">{{item.title}}</a-select-option>
                    </a-select>
                  </a-form-model-item>
                  <a-form-model-item prop="expirationDate" label="到期时间" ref="expirationDate">
                    <a-date-picker size="small" valueFormat="YYYY-MM-DD" v-model="addForm.expirationDate" @change="changeExpirationDate" />
                  </a-form-model-item>
                  <a-form-model-item prop="multifactorStatus" label="双因素认证" ref="multifactorStatus" v-show="addForm.serviceType !== 'rest' && addForm.serviceType !== 'ldap'">
                    <a-radio-group v-model="addForm.multifactorStatus">
                      <a-radio value="off">关闭</a-radio>
                      <a-radio value="on">开启</a-radio>
                      <a-radio value="user">人员</a-radio>
                      <a-radio value="tag">标签</a-radio>
                    </a-radio-group>
                  </a-form-model-item>
                  <a-form-model-item prop="multifactorMethod" label="双因素类型" ref="multifactorMethod" v-show="(addForm.multifactorStatus === 'on' || addForm.multifactorStatus === 'user' || addForm.multifactorStatus === 'tag') && addForm.serviceType !== 'ldap'">
                    <a-radio-group v-model="addForm.multifactorMethod">
                      <a-radio value="sms">短信验证码</a-radio>
                      <a-radio value="totp">TOTP验证码</a-radio>
                    </a-radio-group>
                  </a-form-model-item>
                  <a-form-model-item prop="multifactorTags" label="双因素类型标签" ref="multifactorTags" v-show="addForm.multifactorStatus === 'tag'">
                    <el-select
                      v-model="addForm.multifactorTags"
                      multiple
                      size="mini"
                      clearable
                      style="width: 100%"
                      placeholder="请选择标签"
                    >
                      <el-option
                        v-for="(item, index) in filters['tag']"
                        :key="index"
                        :label="item.title"
                        :value="item.key">
                      </el-option>
                    </el-select>
                  </a-form-model-item>
                  <a-form-model-item prop="authorizedToReleaseProxyGrantingTicket" label="允许作为代理认证提供者" ref="authorizedToReleaseProxyGrantingTicket" v-show="addForm.serviceType !== 'ldap'">
                    <a-switch v-model.trim="addForm.authorizedToReleaseProxyGrantingTicket" size="small" />
                  </a-form-model-item>
                  <a-form-model-item prop="proxyPolicy" label="允许代理认证" ref="proxyPolicy" v-show="addForm.serviceType !== 'ldap'">
                    <div style="display:flex;">
                      <div style="display:flex;flex: 0 0 110px;height:24px;line-height:24px;align-items:center;">
                        <a-radio-group v-model="addForm.proxyPolicy">
                          <a-radio value="refuse">否</a-radio>
                          <a-radio value="regex">是</a-radio>
                        </a-radio-group>
                      </div>
                      <div style="display:flex;flex:1;">
                        <a-input type="text" v-show="addForm.proxyPolicy === 'regex'" size="small" placeholder="允许代理认证的服务" v-model.trim="addForm.proxyPolicyPattern" style="width: 100%;" />
                      </div>
                    </div>
                  </a-form-model-item>
                  <!-- <a-form-model-item prop="proxyPolicyPattern" v-show="addForm.proxyPolicy === 'regex'" label="允许代理认证正则表达式" ref="proxyPolicyPattern">
                    <a-input type="text" size="small" placeholder="允许代理认证的服务" v-model.trim="addForm.proxyPolicyPattern" style="width: 100%" />
                  </a-form-model-item> -->
                  <a-form-model-item prop="description" label="备注" ref="description" v-show="addForm.serviceType !== 'ldap'">
                    <a-textarea
                      style="width: 100%"
                      v-model="addForm.description"
                      placeholder="请输入备注"
                      :auto-size="{ minRows: 3, maxRows: 5 }"
                    />
                  </a-form-model-item>
                </div>
              </div>
              <div class="drawer-item auto" ref="contacts" v-show="addForm.serviceType !== 'ldap'">
                <div class="drawer-title font16 grey999"><b>联系人（{{addForm.contacts.length}}人）</b><span class="font14">（选填）</span></div>
                <div class="drawer-content auto">
                  <div class="auto">
                    <custom-page>
                      <div slot="custom">
                        <a-button type="primary" size="small" class="m-r-5 fl" @click="addContact"> 添加联系人 </a-button>
                      </div>
                    </custom-page>
                  </div>
                  <div class="m-t-10 auto">
                    <a-table :rowKey="(record,index)=>{return index}" size="middle" :columns="contactTableHeader" :data-source="addForm.contacts" :pagination="false">
                      <div slot="department" slot-scope="text, record">
                        <!-- {{departmentName[text]}} -->
                        {{text}}
                      </div>
                      <div slot="action" slot-scope="text, record, index">
                        <a-button type="primary" size="small" class="m-r-5" @click="editContacts(record, index)">编辑</a-button>
                        <a-button type="primary" size="small" class="bgred borred" @click="deleteContacts(index)">删除</a-button>
                      </div>
                    </a-table>
                  </div>
                </div>
              </div>
              <div class="drawer-item auto" ref="auth" id="whitebox" v-show="addForm.serviceType !== 'ldap'">
                <div class="drawer-title font16 grey999"><b>白名单（{{addForm.whiteTags.length}}个）</b><span class="font14">（选填）</span></div>
                <div class="drawer-content-warp auto">
                  <div class="drawer-content auto drawer-content3" v-if="filters['tag']">
                    <a-transfer
                      :list-style="{
                        width: 'calc(35% - 20px)',
                      }"
                      :data-source="filters['tag']"
                      :titles="['可选白名单', '已选白名单']"
                      :target-keys="addForm.whiteTags"
                      :show-select-all="false"
                      @change="handleWhiteTagsChange"
                      :render="renderItem"
                    >
                      <template
                        slot="children"
                        slot-scope="{
                          props: { direction, filteredItems, selectedKeys, disabled: listDisabled },
                          on: { itemSelectAll, itemSelect },
                        }"
                      >
                        <a-table
                          :pagination="false"
                          :row-selection="
                            getRowSelection({ disabled: listDisabled, selectedKeys, itemSelectAll, itemSelect })
                          "
                          :columns="direction === 'left' ? leftColumns : rightColumns"
                          :data-source="filteredItems"
                          size="small"
                          :scroll="{y: 300}"
                          :style="{ pointerEvents: listDisabled ? 'none' : null, maxHeight: '500px' }"
                          :custom-row="
                            ({ key: key, disabled: itemDisabled }) => ({
                              on: {
                                click: (event) => {
                                  if (itemDisabled || listDisabled) return;
                                  clickWhiteRow(direction, key)
                                },
                              },
                            })
                          "
                        />
                      </template>
                      <!-- itemSelect(key, !selectedKeys.includes(key)); -->
                    </a-transfer>
                  </div>
                  <div class="taginfo auto" v-if="whiteTagsArray.length && whiteCurrentIndex !== null">
                    <div class="taginfo-title auto">白名单有效时间（{{whiteCurrentRow['title']}}）</div>
                    <div class="taginfo-content auto">
                      <a-radio-group v-model="whiteTagsArray[whiteCurrentIndex]['validUntil']">
                        <a-radio :style="radioStyle" :value="false">一直有效</a-radio>
                        <a-radio :style="radioStyle" :value="true">
                          <a-range-picker v-model="whiteTagsArray[whiteCurrentIndex]['time']" valueFormat="YYYY-MM-DD" size="small" :allowClear="false" style="width:224px;">
                            <a-icon slot="calendar" type="smile" />
                          </a-range-picker>
                        </a-radio>
                      </a-radio-group>
                    </div>
                  </div>
                </div>
              </div>
              <div class="drawer-item auto" id="blackbox" v-show="addForm.serviceType !== 'ldap'">
                <div class="drawer-title font16 grey999"><b>黑名单（{{addForm.blackTags.length}}个）</b><span class="font14">（选填）</span></div>
                <div class="drawer-content-warp auto">
                  <div class="drawer-content auto drawer-content3" v-if="filters['tag']">
                    <a-transfer
                      :show-select-all="false"
                      :list-style="{
                        width: 'calc(35% - 20px)',
                      }"
                      :data-source="filters['tag']"
                      :titles="['可选黑名单', '已选黑名单']"
                      :target-keys="addForm.blackTags"
                      @change="handleBlackTagsChange"
                      :render="renderItem"
                    >
                      <template
                        slot="children"
                        slot-scope="{
                          props: { direction, filteredItems, selectedKeys, disabled: listDisabled },
                          on: { itemSelectAll, itemSelect },
                        }"
                      >
                        <a-table
                          :pagination="false"
                          :row-selection="
                            getRowSelection({ disabled: listDisabled, selectedKeys, itemSelectAll, itemSelect })
                          "
                          :columns="direction === 'left' ? leftColumns : rightColumns"
                          :data-source="filteredItems"
                          size="small"
                          :scroll="{y: 300}"
                          :style="{ pointerEvents: listDisabled ? 'none' : null, maxHeight: '500px' }"
                          :custom-row="
                            ({ key: key, disabled: itemDisabled }) => ({
                              on: {
                                click: (event) => {
                                  if (itemDisabled || listDisabled) return;
                                  clickBlackRow(direction, key)
                                },
                              },
                            })
                          "
                        />
                      </template>
                    </a-transfer>
                  </div>
                  <div class="taginfo auto" v-if="blackTagsArray.length && blackCurrentIndex !== null">
                    <div class="taginfo-title auto">黑名单有效时间（{{blackCurrentRow['title']}}）</div>
                    <div class="taginfo-content auto">
                      <a-radio-group v-model="blackTagsArray[blackCurrentIndex]['validUntil']">
                        <a-radio :style="radioStyle" :value="false">一直有效</a-radio>
                        <a-radio :style="radioStyle" :value="true">
                          <a-range-picker v-model="blackTagsArray[blackCurrentIndex]['time']" valueFormat="YYYY-MM-DD" size="small" :allowClear="false" style="width:224px;">
                            <a-icon slot="calendar" type="smile" />
                          </a-range-picker>
                        </a-radio>
                      </a-radio-group>
                    </div>
                  </div>
                </div>
              </div>
            </a-form-model>
            <div class="drawer-bar drawer-bar-bot auto">
              <a-button type="primary" size="small" class="m-r-10" @click="saveAccess">保存</a-button>
            </div>
          </a-spin>
        </a-layout-content>
      </a-layout>
    </a-drawer>

    <a-modal v-model="contactVisible" title="编辑联系人信息" @cancel="contactCancel" @ok="contactClose" width="434px">
      <div id="no-margin-form">
        <a-form-model
            ref="contactForm"
            :rules="contactRules"
            :model="contactForm"
            class="ant-advanced-search-form"
            :label-col="{span: 5}"
            :wrapper-col="{span: 19}"
          >
          <a-form-model-item label="学工号" prop="schoolid">
            <a-input v-model="contactForm.schoolid" placeholder="请输入学工号" size="small"/>
          </a-form-model-item>
          <a-form-model-item label="姓名" prop="name">
            <a-input v-model="contactForm.name" placeholder="请输入姓名" size="small"/>
          </a-form-model-item>
          <a-form-model-item label="电话" prop="phone">
            <a-input v-model="contactForm.phone" placeholder="请输入电话" size="small"/>
          </a-form-model-item>
          <a-form-model-item label="邮箱" prop="email">
            <a-input v-model="contactForm.email" placeholder="请输入邮箱" size="small"/>
          </a-form-model-item>
          <a-form-model-item label="所属单位" prop="department">
            <!-- <el-cascader v-model="contactForm.department" :show-all-levels="false" :props="{ label: 'name', value: 'id', children: 'children', emitPath: false }" :options="filters['department']" size="mini" style="width:100%;"></el-cascader> -->
            <a-input v-model="contactForm.department" placeholder="请输入所属单位" size="small"/>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </a-layout>
</template>

<script>
import difference from 'lodash/difference';
import { Access, Filter } from '@/model'
export default {
  data () {
    return {
      listDisabled: false,
      menuIndex: 1,
      overflowText: 'auto',
      loading: true,
      isAdd: false,
      departmentName: {},
      filters: {},
      filter: {
        search: '',
        page: 1,
        limit: 10
      },
      serviceTypeOptions: [
        {"id":"web","name":"Web服务"},
        {"id":"rest","name":"RestfulAPI"},
        {"id":"oauth","name":"Oauth"},
        {"id":"ldap","name":"LDAP协议"}
      ],
      count: 0,
      enabled: 0,
      serviceCount: {},
      tablecolumns: [
        {
          title: '匹配顺序',
          dataIndex: 'order'
        },
        {
          title: '系统名称',
          dataIndex: 'name'
        },
        {
          title: 'URL规则',
          dataIndex: 'serviceId',
          width: '250px'
        },
        {
          title: '接入方式',
          dataIndex: 'serviceType',
          scopedSlots: { customRender: 'serviceType' }
        },
        {
          title: '启用状态',
          dataIndex: 'enabled',
          scopedSlots: { customRender: 'enabled' }
        },
        {
          title: '有效期',
          dataIndex: 'expirationDate',
          scopedSlots: { customRender: 'expirationDate' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          width: '150px'
        }
      ],
      tableData: [],
      totalData: [],
      visible: false,
      addForm: {
        serviceType: 'web',
        name: '',
        serviceId: '',
        order: 0,
        // enabled: true,
        enabledSso: true,
        restfuls: [],
        allowedAttributes: [],
        // expirationDate: '',
        multifactorStatus: 'off',
        multifactorMethod: 'sms',
        multifactorTags: [],
        authorizedToReleaseProxyGrantingTicket: false,
        proxyPolicy: 'refuse',
        proxyPolicyPattern: '',
        description: '',
        appId: '',
        appSecert: '',
        contacts: [],
        whiteTags: [],
        blackTags: [],

        username: '',
        password: '',
        baseDn: '',
        manageDn: '',
        enabled: true,
        expirationDate: '',
        allowedTags: []
      },
      addRules: {
        name: [{ required: true, message: '请填写系统名称', trigger: 'change' }],
        serviceId: [{ required: true, message: '请填写URL规则', trigger: 'change' }],
        username: [{ required: false, message: '请填写用户名', trigger: 'change' }],
        multifactorMethod: [{ required: true, message: '请选择双因素类型', trigger: 'change' }],
        multifactorTags: [{ required: false, type: 'array', message: '请选择双因素类型标签', trigger: 'change' }]
      },
      contactTableHeader: [
        {
          title: '学工号',
          dataIndex: 'schoolid'
        },
        {
          title: '姓名',
          dataIndex: 'name'
        },
        {
          title: '电话',
          dataIndex: 'phone'
        },
        {
          title: '邮箱',
          dataIndex: 'email'
        },
        {
          title: '所属单位',
          dataIndex: 'department',
          scopedSlots: { customRender: 'department' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
          width: 200
        }
      ],
      contactVisible: false,
      mockData: [],
      targetKeys: [],
      contactForm: {
        schoolid: '',
        name: '',
        phone: '',
        email: '',
        department: ''
      },
      contactRules: {
        schoolid: [
          { required: false, message: '请填写学工号', trigger: 'blur' }
        ],
        name: [
          { required: false, message: '请填写姓名', trigger: 'blur' }
        ],
        phone: [
          { required: false, message: '请填写电话', trigger: 'blur' }
        ],
        department: [
          { required: false, message: '请选择单位', trigger: 'change' }
        ]
      },
      searchInterval: null,
      isAddRestful: false,
      newRestful: '',
      current_row: {},
      isEditContacts: false,
      editContactIndex: 0,
      editLoading: false,
      showPass: true,
      fakepass: '123456789',
      whiteTagsArray: [],
      whiteCurrentIndex: null,
      whiteCurrentRow: {},
      blackTagsArray: [],
      blackCurrentIndex: null,
      blackCurrentRow: {},
      radioStyle: {
        display: 'flex',
        height: '30px',
        lineHeight: '30px',
      },
      leftColumns: [
        {
          dataIndex: 'title',
          title: '名称',
          ellipsis: true
        }
      ],
      rightColumns: [
        {
          dataIndex: 'title',
          title: '名称',
          ellipsis: true
        }
      ],
      domain: '',
      repeatNameStatus: false,
      repeatServiceIdStatus: false
    }
  },
  watch: {
    'filter.search': function (val) {
      // this.getServiceList(val)
      if (this.searchInterval) {
        clearInterval(this.searchInterval)
        this.searchInterval = setTimeout(() => {
          this.getTableData(true)
        }, 200)
      } else {
        this.searchInterval = setTimeout(() => {
          this.getTableData(true)
        }, 200)
      }
    },
    'filter.page': function (val) {
      this.getTableData()
    },
    'filter.limit': function (val) {
      this.getTableData(true)
    },
    'addForm.serviceType': function (val) {
      setTimeout(() => {
        if (val === 'oauth' && !this.addForm.appId) {
          this.getAppAecret('all')
        }
        if (val === 'rest') {
          this.addForm.multifactorStatus = 'off'
        }
      }, 200)
    },
    'addForm.multifactorStatus': function (val) {
      if (val === 'on' || val === 'user' || val === 'tag') {
        this.addRules['multifactorMethod'][0]['required'] = true
      } else {
        this.addRules['multifactorMethod'][0]['required'] = false
      }
      if (val === 'tag') {
        this.addRules['multifactorTags'][0]['required'] = true
      } else {
        this.addRules['multifactorTags'][0]['required'] = false
      }
    },
    'visible': function (val) {
      if (val) {
        this.overflowText = 'hidden'
      } else {
        this.overflowText = 'auto'
      }
    }
  },
  methods: {
    changeEnabled (e) {
      console.log(e)
      if (this.addForm.expirationDate) {
        if (e) { // 启用状态
          if (new Date(this.addForm.expirationDate).getTime() < new Date().getTime()) {
            this.$error({
              title: '',
              content: '启用状态下过期时间不能小于当前时间！',
              onOk: async () => {
                this.addForm.expirationDate = ''
              }
            })
          }
        } else { // 停用状态
          if (new Date(this.addForm.expirationDate).getTime() > new Date().getTime()) {
            this.$error({
              title: '',
              content: '停用状态下过期时间不能大于当前时间！',
              onOk: async () => {
                this.addForm.expirationDate = ''
              }
            })
          }
        }
      }
    },
    changeExpirationDate (e) {
      if (this.addForm.enabled) { // 启用状态
        if (new Date(e).getTime() < new Date().getTime()) {
          this.$error({
            title: '',
            content: '启用状态下过期时间不能小于当前时间！',
            onOk: async () => {
              this.addForm.expirationDate = ''
            }
          })
        }
      } else { // 停用状态
        if (new Date(e).getTime() > new Date().getTime()) {
          this.$error({
            title: '',
            content: '停用状态下过期时间不能大于当前时间！',
            onOk: async () => {
              this.addForm.expirationDate = ''
            }
          })
        }
      }
    },
    checkNameRepeat (e) {
      let repeatItems = this.tableData.filter((item) => {
        return item.name === e.target.value
      })
      if (this.isAdd) { // 新增条目
        if (repeatItems.length) {
          this.repeatNameStatus = true
        } else {
          this.repeatNameStatus = false
        }
      } else { // 修改条目
        if (repeatItems.length) { // 有重复的
          if (repeatItems.length > 1) { // 有多条肯定算重复
            this.repeatNameStatus = true
          } else { // 只有一条重复的
            if (repeatItems[0].id === this.current_row.id) { // 重复的是当前修改的这条
              this.repeatNameStatus = false
            } else { // 重复的不是当前修改的这条
              this.repeatNameStatus = true
            }
          }
        } else { // 没有重复的
          this.repeatNameStatus = false
        }
      }
    },
    checkServiceIdRepeat (e) {
      let repeatItems = this.tableData.filter((item) => {
        return item.serviceId === e.target.value
      })
      if (this.isAdd) { // 新增条目
        if (repeatItems.length) {
          this.repeatServiceIdStatus = true
        } else {
          this.repeatServiceIdStatus = false
        }
      } else { // 修改条目
        if (repeatItems.length) { // 有重复的
          if (repeatItems.length > 1) { // 有多条肯定算重复
            this.repeatServiceIdStatus = true
          } else { // 只有一条重复的
            if (repeatItems[0].id === this.current_row.id) { // 重复的是当前修改的这条
              this.repeatServiceIdStatus = false
            } else { // 重复的不是当前修改的这条
              this.repeatServiceIdStatus = true
            }
          }
        } else { // 没有重复的
          this.repeatServiceIdStatus = false
        }
      }
    },
    clickBlackRow (direction, key) {
      if (direction === 'left') {
        this.blackCurrentIndex = null
      } else {
        this.blackCurrentIndex = this.addForm.blackTags.findIndex(item => {return item === key})
        this.blackCurrentRow = this.filters['tag'].find(item => {return item.key === String(key)})
      }
    },
    clickWhiteRow (direction, key) {
      if (direction === 'left') {
        this.whiteCurrentIndex = null
      } else {
        this.whiteCurrentIndex = this.addForm.whiteTags.findIndex(item => {return item === key})
        this.whiteCurrentRow = this.filters['tag'].find(item => {return item.key === String(key)})
      }
    },
    getRowSelection({ disabled, selectedKeys, itemSelectAll, itemSelect }) {
      return {
        getCheckboxProps: item => ({ props: { disabled: disabled || item.disabled } }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter(item => !item.disabled)
            .map(({ key }) => key);
          const diffKeys = selected
            ? difference(treeSelectedKeys, selectedKeys)
            : difference(selectedKeys, treeSelectedKeys);
          itemSelectAll(diffKeys, selected);
        },
        onSelect({ key }, selected) {
          itemSelect(key, selected);
        },
        selectedRowKeys: selectedKeys,
      };
    },
    blurUsername () {
      if (this.addForm.username && this.addForm.serviceType === 'ldap') {
        this.createDn(this.addForm.username)
        // this.getGenerateDn()
      }
      if (!this.addForm.username && this.addForm.serviceType === 'ldap') {
        this.addForm.baseDn = ''
        this.addForm.manageDn = ''
      }
    },
    changeServiceType (e) {
      if (e === 'ldap') {
        this.showPass = true
        this.getGeneratePwd()
        this.addRules['serviceId'][0]['required'] = false
        this.addRules['multifactorMethod'][0]['required'] = false
        this.addRules['multifactorTags'][0]['required'] = false
        this.addRules['username'][0]['required'] = true
      } else {
        this.addRules['serviceId'][0]['required'] = true
        this.addRules['username'][0]['required'] = false
      }
    },
    showPassFn () {
      console.log(123)
      this.showPass = true
    },
    async getGeneratePwd () {
      this.showPass = true
      console.log(111)
      let len = 16
      let cha = '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
      let pasArr = []
      for (let i = 0; i < len; i++) {
        pasArr[i] = cha[0 | Math.random() * cha.length]
      }
      this.addForm.password = pasArr.join('')
      // let res = await Access.getGeneratePwd()
      // if (!res['code']) {
      //   this.showPass = true
      //   this.addForm.password = res['data']['password']
      // } else {
      //   this.$message.error(this.errMsg[res['code']])
      // }
    },
    createDn (username) {
      var dn = 'uid=' + username + ', ou=system' + this.domain.split('.').map(e => ', dc=' + e).join('')
      this.addForm.manageDn = dn
    },
    createBaseDn () {
      var baseDn = 'ou=people' + this.domain.split('.').map(e => ', dc=' + e).join('')
      this.addForm.baseDn = baseDn
    },
    async getGenerateDn () {
      let res = await Access.getGenerateDn({
        username: this.addForm.username
      })
      if (!res['code']) {
        this.addForm.baseDn = res['data']['baseDn']
        this.addForm.manageDn = res['data']['manageDn']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    renderItem (item) {
      // console.log(item)
      // if (item.type === 'dynamic') {
      //   // let span = document.createElement('span')
      //   // span.innerHTML = item.title
      //   // return span
      //   // return (<a-icon type="tags" theme="filled" /><span>{item.title}</span>)
      //   return `<a-icon type="tags" theme="filled" /><span>${item.title}</span>`
      // } else {
      //   return `<a-icon type="tags" theme="filled" /><span>${item.title}</span>`
      // }
      // return item.title
      if (item.type === 'dynamic') {
        return '[动态] ' + item.title
      } else {
        return '[静态] ' + item.title
      }
    },
    scrollForm (e) {
      let basic = this.$refs['basic'].offsetTop
      let contacts = this.$refs['contacts'].offsetTop
      let auth = this.$refs['auth'].offsetTop
      let scrollTop = e.target.scrollTop
      if (scrollTop > basic) {
        this.menuIndex = 1
      }
      if (scrollTop > contacts) {
        this.menuIndex = 2
      }
      if (scrollTop > auth) {
        this.menuIndex = 3
      }
    },
    async disabledOff (row) {
      console.log(row)
      let res = null
      if (row.serviceType === 'ldap') {
        res = await Access.deleteLdapAccess(row.id)
      } else {
        res = await Access.deleteAccess(row.id)
      }
      if (!res['code']) {
        this.$message.success('删除成功！')
        this.getAccessList()
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    handleWhiteTagsChange (nextTargetKeys, direction, moveKeys) {
      this.addForm.whiteTags = nextTargetKeys
      if (direction === 'right') {
        nextTargetKeys.map(item => {
          let status = true
          for (let key in this.whiteTagsArray) {
            if (item === this.whiteTagsArray[key]['tagid']) {
              status = false
            }
          }
          if (status) {
            this.whiteTagsArray.push({
              tagid: item,
              validUntil: false,
              startDate: '',
              endDate: '',
              time: []
            })
          }
        })
      } else {
        let newArray = []
        nextTargetKeys.map(item => {
          for (let key in this.whiteTagsArray) {
            if (item === this.whiteTagsArray[key]['tagid']) {
              newArray.push(this.whiteTagsArray[key])
              break
            }
          }
        })
        this.whiteTagsArray = newArray
      }
      console.log('whiteTagsArray', this.whiteTagsArray)
      this.whiteTagsArray = JSON.parse(JSON.stringify(this.whiteTagsArray))
    },
    handleBlackTagsChange (nextTargetKeys, direction, moveKeys) {
      this.addForm.blackTags = nextTargetKeys
      if (direction === 'right') {
        nextTargetKeys.map(item => {
          let status = true
          for (let key in this.blackTagsArray) {
            if (item === this.blackTagsArray[key]['tagid']) {
              status = false
            }
          }
          if (status) {
            this.blackTagsArray.push({
              tagid: item,
              validUntil: false,
              startDate: '',
              endDate: '',
              time: []
            })
          }
        })
      } else {
        let newArray = []
        nextTargetKeys.map(item => {
          for (let key in this.blackTagsArray) {
            if (item === this.blackTagsArray[key]['tagid']) {
              newArray.push(this.blackTagsArray[key])
              break
            }
          }
        })
        this.blackTagsArray = newArray
      }
      this.blackTagsArray = JSON.parse(JSON.stringify(this.blackTagsArray))
    },
    async editHandle (row) {
      this.repeatNameStatus = false
      this.repeatServiceIdStatus = false
      this.showPass = false
      if (row.serviceType !== 'ldap') {
        this.addRules['serviceId'][0]['required'] = true
        this.addRules['username'][0]['required'] = false
        for (let key in this.serviceTypeOptions) {
          if (this.serviceTypeOptions[key]['id'] === 'ldap') {
            this.serviceTypeOptions[key]['disabled'] = true
          } else {
            this.serviceTypeOptions[key]['disabled'] = false
          }
        }
      } else {
        this.addRules['serviceId'][0]['required'] = false
        this.addRules['multifactorMethod'][0]['required'] = false
        this.addRules['username'][0]['required'] = true
        for (let key in this.serviceTypeOptions) {
          if (this.serviceTypeOptions[key]['id'] !== 'ldap') {
            this.serviceTypeOptions[key]['disabled'] = true
          } else {
            this.serviceTypeOptions[key]['disabled'] = false
          }
        }
      }
      this.serviceTypeOptions = JSON.parse(JSON.stringify(this.serviceTypeOptions))
      this.editLoading = true
      this.current_row = row
      this.isAdd = false
      this.visible = true
      let res = null
      if (row.serviceType === 'ldap') {
        res = await Access.getLdapDetail(row.id)
      } else {
        res = await Access.getAppDetail(row.id)
      }
      this.editLoading = false
      if (!res['code']) {
        if (row.serviceType === 'ldap') {
          this.addForm['serviceType'] = res['data']['serviceType']
          this.addForm['name'] = res['data']['name']
          this.addForm['username'] = res['data']['username']
          this.addForm['password'] = res['data']['password']
          this.addForm['baseDn'] = res['data']['baseDn']
          this.addForm['manageDn'] = res['data']['manageDn']
          this.addForm['enabled'] = res['data']['enabled']
          this.addForm['expirationDate'] = res['data']['expirationDate']
          this.addForm['allowedAttributes'] = res['data']['allowedAttributes']
          if (res['data']['allowedTags'] && res['data']['allowedTags'].length > 0) {
            var tags = []
            res['data']['allowedTags'].map(item => {
              tags.push(String(item))
            })
            this.addForm['allowedTags'] = tags
          } else {
            this.addForm['allowedTags'] = []
          }
        } else {
          res['data']['multifactorTags'].map((item, index) => {
            res['data']['multifactorTags'][index] = String(item)
          })
          this.addForm['serviceType'] = res['data']['serviceType']
          this.addForm['name'] = res['data']['name']
          this.addForm['serviceId'] = res['data']['serviceId']
          this.addForm['order'] = res['data']['order']
          this.addForm['enabled'] = res['data']['enabled']
          this.addForm['enabledSso'] = res['data']['enabledSso']
          this.addForm['restfuls'] = res['data']['restfuls']
          this.addForm['allowedAttributes'] = res['data']['allowedAttributes']
          this.addForm['expirationDate'] = res['data']['expirationDate']
          this.addForm['multifactorStatus'] = res['data']['multifactorStatus']
          this.addForm['multifactorMethod'] = res['data']['multifactorMethod']
          this.addForm['multifactorTags'] = res['data']['multifactorTags']
          this.addForm['authorizedToReleaseProxyGrantingTicket'] = res['data']['authorizedToReleaseProxyGrantingTicket']
          this.addForm['proxyPolicy'] = res['data']['proxyPolicy']
          this.addForm['proxyPolicyPattern'] = res['data']['proxyPolicyPattern']
          this.addForm['description'] = res['data']['description']
          this.addForm['appId'] = res['data']['appId']
          this.addForm['appSecert'] = res['data']['appSecert']
          this.addForm['contacts'] = res['data']['contacts']
          // console.log('this.addForm', this.addForm)
          if (res['data']['whiteTags'].length) {
            let tagArr = []
            res['data']['whiteTags'].map(item => {
              tagArr.push(String(item.tagid))
              if (item['validUntil']) {
                item['time'] = [item['startDate'], item['endDate']]
                this.whiteTagsArray.push(item)
              } else {
                item['time'] = []
                this.whiteTagsArray.push(item)
              }
            })
            this.addForm['whiteTags'] = tagArr
          } else {
            this.addForm['whiteTags'] = res['data']['whiteTags']
          }
          if (res['data']['blackTags'].length) {
            let tagArr = []
            res['data']['blackTags'].map(item => {
              tagArr.push(String(item.tagid))
              if (item['validUntil']) {
                item['time'] = [item['startDate'], item['endDate']]
                this.blackTagsArray.push(item)
              } else {
                item['time'] = []
                this.blackTagsArray.push(item)
              }
            })
            this.addForm['blackTags'] = tagArr
          } else {
            this.addForm['blackTags'] = res['data']['blackTags']
          }
          if (this.addForm.multifactorStatus === 'on' || this.addForm.multifactorStatus === 'user' || this.addForm.multifactorStatus === 'tag') {
            this.addRules['multifactorMethod'][0]['required'] = true
          } else {
            this.addRules['multifactorMethod'][0]['required'] = false
          }
          if (this.addForm.multifactorStatus === 'tag') {
            this.addRules['multifactorTags'][0]['required'] = true
          } else {
            this.addRules['multifactorTags'][0]['required'] = false
          }
          // console.log('this.addForm', this.addForm)
        }
        // this.addForm['order'] = 100
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    saveAccess () {
      // if (this.repeatNameStatus) {
      //   this.$message.error('系统名称重复！')
      //   return false
      // }
      // if (this.repeatServiceIdStatus) {
      //   this.$message.error('URL规则重复！')
      //   return false
      // }
      this.$refs['addForm'].validate( async (validate, object) => {
        if (validate) {
          this.editLoading = true
          let form = JSON.parse(JSON.stringify(this.addForm))
          if (form.whiteTags.length && this.whiteTagsArray) {
            let newWhiteArr = []
            this.whiteTagsArray.map(item => {
              if (!item.validUntil) {
                newWhiteArr.push({
                  tagid: item.tagid,
                  validUntil: item.validUntil,
                  startDate: '',
                  endDate: '',
                })
              } else {
                newWhiteArr.push({
                  tagid: item.tagid,
                  validUntil: item.validUntil,
                  startDate: item.time[0],
                  endDate: item.time[1],
                })
              }
            })
            form.whiteTags = newWhiteArr
          }
          if (form.blackTags.length && this.blackTagsArray) {
            let newBlackArr = []
            this.blackTagsArray.map(item => {
              if (!item.validUntil) {
                newBlackArr.push({
                  tagid: item.tagid,
                  validUntil: item.validUntil,
                  startDate: '',
                  endDate: '',
                })
              } else {
                newBlackArr.push({
                  tagid: item.tagid,
                  validUntil: item.validUntil,
                  startDate: item.time[0],
                  endDate: item.time[1],
                })
              }
            })
            form.blackTags = newBlackArr
          }
          let res = null
          if (!this.isAdd) {
            if (this.addForm.serviceType === 'ldap' ) {
              res = await Access.changeLdap(this.current_row.id, form)
            } else {
              res = await Access.changeAccess(this.current_row.id, form)
            }
          } else {
            if (this.addForm.serviceType === 'ldap' ) {
              res = await Access.addLdap(form)
            } else {
              res = await Access.saveAccess(form)
            }
          }
          this.editLoading = false
          if (!res['code']) {
            this.onClose()
            this.getAccessList()
            this.$message.success('操作成功！')
          } else {
            this.$message.error(this.errMsg[res['code']])
          }
        } else {
          this.scrollView(object)
        }
      })
    },
    scrollView(object) {
      // console.log('object', object)
      for (const i in object) {
        let dom = this.$refs[i]
        // 这里是针对遍历的情况（多个输入框），取值为数组
        if (Object.prototype.toString.call(dom) !== '[object Object]') { 
          dom = dom[0]
        }
        // 第一种方法（包含动画效果）
        dom.$el.scrollIntoView({ // 滚动到指定节点
          // 值有start,center,end，nearest，当前显示在视图区域中间
          block: 'center', 
          // 值有auto、instant,smooth，缓动动画（当前是慢速的）
          behavior: 'smooth' 
        })
        break // 跳出循环了
      }
    },
    async getAppAecret (type) {
      console.log(type)
      let res = await Access.getAppGenerate(type)
      if (!res['code']) {
        if (type === 'all') {
          this.addForm.appId = res['data']['appid']
          this.addForm.appSecert = res['data']['appsecret']
        }
        if (type === 'appsecret') {
          this.addForm.appSecert = res['data']['appsecret']
        }
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    addRestfuls () {
      this.newRestful = ''
      this.isAddRestful = true
    },
    submitRestfuls () {
      if (!this.newRestful) {
        this.$message.error('请填写内容！')
        return false
      }
      // console.log('this.newRestful.split(".")', this.newRestful.split('.'))
      // if (this.newRestful.split('.').length !== 4) {
      //   this.$message.error('请填写正确的IP！')
      //   return false
      // }
      // for (let key in this.newRestful.split('.')) {
      //   let item = this.newRestful.split('.')[key]
      //   if (!(/(^[1-9]\d*$)/.test(item))) {
      //     this.$message.error('请填写正确的IP！')
      //     return false
      //   }
      // }
      this.addForm.restfuls.push(this.newRestful)
      this.isAddRestful = false
    },
    deleteRestfuls (index) {
      this.addForm.restfuls.splice(index, 1)
    },
    getPageMes (mes) {
      this.filter.page = mes.pageNumber
      this.filter.limit = mes.pageSize
    },
    displayRender({ labels }) {
      return labels[labels.length - 1];
    },
    addContact () {
      this.isEditContacts = false
      this.contactVisible = true
    },
    changeMenu (index) {
      setTimeout(() => {
        this.menuIndex = index
      }, 500)
      if (index === 1) {
        this.$refs['basic'].scrollIntoView({ // 滚动到指定节点
          // 值有start,center,end，nearest，当前显示在视图区域中间
          block: 'start', 
          // 值有auto、instant,smooth，缓动动画（当前是慢速的）
          behavior: 'smooth' 
        })
      }
      if (index === 2) {
        this.$refs['contacts'].scrollIntoView({ // 滚动到指定节点
          // 值有start,center,end，nearest，当前显示在视图区域中间
          block: 'start', 
          // 值有auto、instant,smooth，缓动动画（当前是慢速的）
          behavior: 'smooth' 
        })
      }
      if (index === 3) {
        this.$refs['auth'].scrollIntoView({ // 滚动到指定节点
          // 值有start,center,end，nearest，当前显示在视图区域中间
          block: 'start', 
          // 值有auto、instant,smooth，缓动动画（当前是慢速的）
          behavior: 'smooth' 
        })
      }
    },
    editContacts (row, index) {
      this.isEditContacts = true
      this.editContactIndex = index
      this.contactVisible = true
      this.contactForm = {
        schoolid: row.schoolid,
        name: row.name,
        phone: row.phone,
        email: row.email,
        department: row.department
      }
    },
    deleteContacts (index) {
      this.addForm.contacts.splice(index, 1)
    },
    contactClose () {
      if (!this.contactForm.schoolid && !this.contactForm.name && !this.contactForm.phone && !this.contactForm.email && !this.contactForm.department) {
        this.$message.error('请至少填写1项！')
        return false
      }
      // this.$refs['contactForm'].validate( async validate => {
      //   if (validate) {
      let form = JSON.parse(JSON.stringify(this.contactForm))
      if (this.isEditContacts) {
        this.addForm.contacts.splice(this.editContactIndex, 1, form)
      } else {
        this.addForm.contacts.push(form)
      }
      this.contactCancel()
        // }
      // })
    },
    contactCancel () {
      this.isEditContacts = false
      this.editContactIndex = 0
      this.contactVisible = false
      this.contactForm = {
        schoolid: '',
        name: '',
        phone: '',
        email: '',
        department: ''
      }
      this.$refs['contactForm'].resetFields()
    },
    addAccess () {
      this.repeatNameStatus = false
      this.repeatServiceIdStatus = false
      this.isAdd = true
      for (let key in this.serviceTypeOptions) {
        this.serviceTypeOptions[key]['disabled'] = false
      }
      this.visible = true
      this.addRules['username'][0]['required'] = false
      this.createBaseDn()
    },
    async getAccessList () {
      this.loading = true
      let res = await Access.getAccessList()
      this.loading = false
      if (!res['code']) {
        this.totalData = res['data']
        this.getTableData(true)
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    async getAccessCount () {
      this.loading = true
      let res = await Access.getAccessCount()
      this.loading = false
      if (!res['code']) {
        // this.tableData = res['data']
      } else {
        this.$message.error(this.errMsg[res['code']])
      }
    },
    getTableData (status) {
      if (status) {
        this.filter.page = 1
      }
      let data = JSON.parse(JSON.stringify(this.totalData))
      console.log('data', data.length)
      let web = 0
      let rest = 0
      let oauth = 0
      let ldap = 0
      let enabled = 0
      if (data.length) {
        let start = (this.filter.page - 1) * this.filter.limit
        let end = this.filter.page * this.filter.limit
        if (this.filter.search) {
          let splitDot = this.filter.search.split('.')
          let splitAll = []
          splitDot.map(item => {
            var splitSpace = item.split(' ')
            splitAll.push.apply(splitAll, splitSpace)
          })
          // console.log(splitAll)
          let searchData = []

          for (let key in data) {
            let item = data[key]
            let nameHas = true
            let urlHas = true
            let ipHas = false
            for (let k in splitAll) {
              if (item.name.indexOf(splitAll[k]) < 0) {
                nameHas = false
                break
              }
            }
            for (let k in splitAll) {
              if (item.serviceType === 'ldap') {
                urlHas = false
                continue
              }
              // console.log(item.serviceId)
              // console.log(splitAll[k])
              // console.log(item.serviceId.indexOf(splitAll[k]))
              if (item.serviceId.indexOf(splitAll[k]) < 0) {
                urlHas = false
                continue
              }
            }
            for (let _k in item.restfuls) {
              if (item.serviceType === 'ldap') {
                ipHas = false
                continue
              }
              let status = true
              for (let k in splitAll) {
                if (item.restfuls[_k].indexOf(splitAll[k]) < 0) {
                  status = false
                  break
                }
              }
              if (!status) {
                continue
              } else {
                ipHas = true
                continue
              }
            }
            if (nameHas || urlHas || ipHas) {
              searchData.push(item)
              continue
            }
          }

          // data.map((item, index) => {
          //   if (new RegExp(item.serviceId).test(this.filter.search) || item.name.indexOf(this.filter.search) > -1 || (item.description && item.description.indexOf(this.filter.search) > -1)) {
          //     searchData.push(item)
          //   } else if (item.contacts && item.contacts.length) {
          //     for (let key in item.contacts) {
          //       if ((item.contacts[key].name && item.contacts[key].name.indexOf(this.filter.search) > -1) || (item.contacts[key].department && item.contacts[key].department.indexOf(this.filter.search) > -1)) {
          //         searchData.push(item)
          //         break
          //       }
          //     }
          //   } else if (item.restfuls && item.restfuls.length) {
          //     for (let key in item.restfuls) {
          //       if (item.restfuls[key].indexOf(this.filter.search) > -1 || new RegExp(item.restfuls[key]).test(this.filter.search)) {
          //         searchData.push(item)
          //       }
          //     }
          //   }
          // })
          this.count = searchData.length
          // console.log('searchData', searchData)
          this.tableData = searchData.slice(start, end)
          // this.tableData = searchData
        } else {
          this.count = data.length
          let start = (this.filter.page - 1) * this.filter.limit
          let end = this.filter.page * this.filter.limit
          this.tableData = data.slice(start, end)
          // this.tableData = data
        }
      } else {
        this.count = 0
        this.tableData = []
      }
      this.totalData.map(item => {
        if (item.serviceType === 'web') {
          web++
        }
        if (item.serviceType === 'rest') {
          rest++
        }
        if (item.serviceType === 'oauth') {
          oauth++
        }
        if (item.serviceType === 'ldap') {
          ldap++
        }
        if (item.enabled === 'Y') {
          enabled++
        }
      })
      this.serviceCount = {
        web: web,
        rest: rest,
        oauth: oauth,
        ldap: ldap
      }
      this.enabled = enabled
      console.log('this.tableData', this.tableData)
      // console.log('this.serviceCount', this.serviceCount)
    },
    onClose () {
      this.$refs.addForm.resetFields()
      this.visible = false
      this.addForm = {
        serviceType: 'web',
        name: '',
        serviceId: '',
        order: 0,
        // enabled: true,
        enabledSso: true,
        restfuls: [],
        allowedAttributes: [],
        // expirationDate: '',
        multifactorStatus: 'off',
        multifactorMethod: 'sms',
        multifactorTags: [],
        authorizedToReleaseProxyGrantingTicket: false,
        proxyPolicy: 'refuse',
        proxyPolicyPattern: '',
        description: '',
        appId: '',
        appSecert: '',
        contacts: [],
        whiteTags: [],
        blackTags: [],

        username: '',
        password: '',
        baseDn: '',
        manageDn: '',
        enabled: true,
        expirationDate: ''
      }
      this.whiteTagsArray = []
      this.whiteCurrentIndex = null
      this.blackTagsArray = []
      this.blackCurrentIndex = null
    },
    handleChange (info) {
      // if (info.file.status !== 'uploading') {
      //   console.log(info.file, info.fileList);
      // }
      if (info.file.status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully`);
        this.form.userImage = info.file.response.url
      } else if (info.file.status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`);
      }
    },
    trunDepartmentData (data) {
      for (let k in data) {
        this.departmentName[data[k]['id']] = data[k]['name']
        if (data[k]['children']) {
          this.trunDepartmentData(data[k]['children'])
        }
      }
    },
    async getCode () {
      let res1 = await Filter.getCode('department')
      this.filters['department'] = res1['data']
      this.trunDepartmentData(res1['data'])
      this.getAccessCode()
    },
    async getAccessCode () {
      let res = await Access.getCode('attr')
      this.filters['attr'] = res['data']
      let res1 = await Access.getCode('tag')
      let tagList = []
      if (res1['data']){
        res1['data'].map(item => {
          tagList.push({
            key: String(item.id),
            title: item.name,
            type: item.type
          })
        })
      }
      this.filters['tag'] = tagList
      let res2 = await Access.getCode('attrldap')
      this.filters['attrldap'] = res2['data']
      this.getAccessList()
    },
    async getDnDomain () {
      let res = await Access.getDnDomain()
      this.domain = res['data']
    }
  },
  created () {
    this.getDnDomain()
    // for (let i = 0; i < 20; i++) {
    //   this.mockData.push({
    //     key: i.toString(),
    //     title: `content${i + 1}`,
    //     description: `description of content${i + 1}`,
    //     disabled: i % 3 < 1,
    //   });
    // }
    // this.targetKeys = this.mockData.filter(item => +item.key % 3 > 1).map(item => item.key)
    this.getCode()
    // this.getAccessCode()
    // this.getAccessList()
    // this.getAccessCount()
  }
}
</script>

<style scoped>
.content-bar {
  padding: 8px 0;
}
.content {
  height: 100%;
}
.batch-menu {
  width: 100%;
  padding-top: 50px;
}
.batch-menu ul li {
  line-height: 50px;
  cursor: pointer;
  font-size: 14px;
  color: rgba(0,0,0,0.65);
}
.batch-menu ul li span {
  display: block;
  width: 140px;
  height: 30px;
  line-height: 30px;
  margin-top: 10px;
  margin-left: 20px;
  text-indent: 22px;
}
.batch-menu ul li.cur span {
  color: #226FBB;
  font-weight: bold;
  background: #E6F7FF;
}

.drawer-bar {
  padding: 5px 0 20px;
  border-bottom: 1px solid rgba(0,0,0,0.06);
}
.drawer-bar-bot {
  padding-top: 20px;
  border: none;
}

.drawer-item {
  padding: 15px 0 20px;
  border-bottom: 1px solid rgba(0,0,0,0.06);
}
.drawer-content-warp {
  width: 100%;
}
.drawer-content {
  margin-top: 5px;
}
.drawer-content3 {
  width: 50%;
  float: left;
  margin-top: 15px;
}
.taginfo-title {
  border-bottom: 1px solid #e8e8e8;
  line-height: 20px;
  padding: 10px 0 10px 0;
  margin: 14px 0 0 20px;
}
.taginfo-content {
  margin: 20px 0 0 20px;
}


.ip-box {
  width: 312px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 100px;
  overflow-y:auto;
}
.restful-item {
  width: 100%;
  height: auto;
  line-height: 22px;
  overflow: hidden;
}
.restful-item:hover {
  background: #E6F7FF;
  cursor: pointer;
}
.restful-item span {
  float: left;
  margin-left: 10px;
}
.restful-item .del-icon {
  float: right;
  margin-top: 4px;
  margin-right: 20px;
  color: #1890FF;
  display: none;
}
.restful-item:hover .del-icon {
  display: block;
}

.checkrepeatitem {
  position: relative;
}
.checkrepeatitem .repeattips {
  position: absolute;
  right: 10px;
  top: -3px;
  line-height: 24px;
  color: red;
  font-size: 12px;
}
</style>
